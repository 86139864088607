<template>
  <List
    title="Quext users"
    resource="quextUsers"
    :basePath="basePath"
    :request-params="{nonDeleted: true}"
    :fields="fields"
    track-by="profileId"
    ref="list"
    on-row-click="edit"
    :search="true"
    search-placeholder="Search for a user by name or email"
    :initial-filter="filter"
    :filter-mapper="mapFilterToQuery"
  >
    <template v-slot:filters="props">
      <div class="form-row form-narrow">
        <SelectInput
          name="role"
          label="Role"
          :options="roles"
        />
        <SelectInput
            name="status"
            label="status"
            placeholder="status"
            :options="statuses"
        />
      </div>
      <div class="form-row form-narrow">
        <DateRangeInput
          label="Registered at"
          name="registeredAt"
          :max-date="today"
        />
        <DateRangeInput
          label="Last login"
          name="lastLogin"
          position="right"
        />
      </div>
      <div class="form-row justify-end">
        <button @click.stop="props.reset()" class="btn-primary btn-transparent mr-8">Reset</button>
        <button class="btn-primary">Filter</button>
      </div>
    </template>
    <template v-slot:actions="actionsScope">
      <div v-if="!actionsScope.record.deletedAt">
        <button
          class="menu-action"
          @click.stop="deleteUser(actionsScope.record)"
        >
          delete
        </button>
        <button
          class="menu-action"
          v-if="actionsScope.record.active"
          @click.stop="blockUser(actionsScope.record)"
        >
          block access
        </button>
        <button
          class="menu-action"
          v-if="!actionsScope.record.active"
          @click.stop="restoreUser(actionsScope.record)"
        >
          restore access
        </button>
      </div>
    </template>
  </List>
</template>

<script>
  import {get} from 'lodash-es';
  import ConfirmationMixin from "@/mixins/ConfirmationMixin";
  import ModalNavigation from '@/mixins/ModalNavigation';
  import NotifyMixin from "@/mixins/NotifyMixin";
  import DateRangeFilterMixin from '@/components/auth/DateRangeFilterMixin';
  import List from '@/components/auth/list/List';
  import DateTimeField from '@/components/auth/list/fields/DateTimeField';
  import SelectInput from '@/components/form/SelectInput';
  import DateRangeInput from '@/components/form/DateRangeInput';
  import UserField from '@/components/auth/list/fields/UserField';
  import StatusField from "@/components/auth/list/fields/StatusField";

  export default {
  name: 'QuextUsers',
  components: {
    SelectInput,
    List,
    DateRangeInput,
  },
  mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin, DateRangeFilterMixin],
  data: function () {
    return {
      roles: [],
      filter: {
        lastLogin: {
          startDate: null,
          endDate: null,
        },
        registeredAt: {
          startDate: null,
          endDate: null,
        },
      },
      fields: [
        {
          name: UserField,
          title: 'Name',
          sortField: 'fullName'
        },
        {
          name: 'role',
          sortField: 'role',
          titleClass: 'text-center',
          dataClass: 'text-center',
        },
        {
          name: StatusField,
          sortField: 'status',
          title: 'Status',
          switch: {
            source: 'status',
          }
        },
        {
          name: DateTimeField,
          sortField: 'createdAt',
          title: 'Registered',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'createdAt',
            showTime: false,
          }
        },
        {
          name: DateTimeField,
          sortField: 'status|asc&lastLogin',
          title: 'Last login',
          titleClass: 'text-center',
          dataClass: 'text-center',
          switch: {
            source: 'lastLogin'
          }
        },
        {
          name: 'actions',
          title: '',
        }
      ],
      statuses: [
        { key: 'ACTIVE', value: 'active' },
        { key: 'INACTIVE', value: 'inactive' },
        { key: 'INVITED', value: 'invited' },
      ],
    };
  },
  computed: {
    basePath: function () {
      return this.$route.path;
    },
  },
  created() {
    this.$authDataProvider.getList('quextUsersRoles')
      .then((roles) => {
        this.roles = roles.map(({name: value, value: key}) => ({key, value}));
      })
      .catch((err) => this.notifyError(err));
  },
  methods: {
    mapFilterToQuery(values) {
      const { from: lastLoginFrom, to: lastLoginTo } = this.getFilterDateRange(values.lastLogin);
      const { from: registrationFrom, to: registrationTo } = this.getFilterDateRange(values.registeredAt);

      return {
        role: get(values, 'role'),
        status: get(values, 'status'),
        lastLoginFrom,
        lastLoginTo,
        registrationFrom,
        registrationTo,
      }
    },
    deleteUser(record) {
      this.requestConfirmation({
        confirmationMessage: `are you sure you want to delete user ${record.fullName}?`,
        confirmBtnText: 'yes',
        cancelBtnText: 'no',
        confirmationType: 'warning'
      })
        .then(confirmed => confirmed
          ? this.$authDataProvider.delete('quextUsers', {id: record.profileId})
            .then(() => {
              this.$refs.list.doSearch();
              this.notifySuccess(`user ${record.fullName} has been deleted`);
            })
            .catch(error => this.notifyError(error.message))
          : null
        );
    },
    blockUser(record) {
      this.requestConfirmation({
        confirmationMessage: 'are you sure you want to block access for this user?',
        confirmBtnText: 'yes',
        cancelBtnText: 'no',
        confirmationType: 'warning'
      })
        .then(confirmed => confirmed
          ? this.$authDataProvider.create('quextUsersDeactivate', {profileId: record.profileId})
            .then(() => {
              this.$refs.list.doSearch();
              this.notifySuccess('user blocked');
            })
            .catch(error => this.notifyError(error.message))
          : null
        );
    },
    restoreUser(record) {
      this.requestConfirmation({
        confirmationMessage: `are you sure you want to restore access for ${record.fullName}?`,
        confirmBtnText: 'yes',
        cancelBtnText: 'no',
        confirmationType: 'success'
      })
        .then(confirmed => confirmed
          ? this.$authDataProvider.create('quextUsersReactivate', {profileId: record.profileId})
            .then(() => {
              this.$refs.list.doSearch();
              this.notifySuccess('user reactivated');
            })
            .catch(error => this.notifyError(error.message))
          : null
        );
    },
  }
};
</script>

<style>
.menu-action {
  @apply flex p-3 w-full;

  &:hover {
    @apply bg-active-100;
  }
}
</style>
